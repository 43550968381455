[class^="file-label"] {
  background-color: #8c52ff;
  color: white;
  padding: 10px;
  cursor: pointer;
  display: inline-block;
  border-radius: 5px;
  transition: background-color 0.3s ease; /* Smooth transition */
}

[class^="file-label"]:hover {
  background-color: #6b40c2;
}
